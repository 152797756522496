* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-tel-input .flag {
  zoom: 1.3 !important;
}

.PrivateTabIndicator-colorSecondary-13 {
  background-color: RGB(175, 0, 0) !important;
}

.MuiFilledInput-input {
  padding: 32px 12px 8px !important;
  color: black !important;
  font-weight: 700 !important;
}

.MuiSelect-iconFilled {
  color: black !important;
}
.MuiTextField-root label {
  font-size: 16px;
  color: black !important;
}
.MuiFormControl-root {
  border-radius: 5px;
}
.MuiFormControl-root .MuiFilledInput-root {
  border-radius: 5px;
}
.MuiOutlinedInput-input {
  font-size: 18px;
  font-weight: 700;
}
.MuiOutlinedInput-input {
  padding: 14px 14px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}
.MuiFormControl-root.MuiFormControl-fullWidth label {
  font-size: 16px;
  font-weight: 600;
  color: RGB(102, 102, 102) !important;
}
.MuiSelect-iconFilled {
  font-size: 26px !important;
}
.MuiFormHelperText-root {
  font-size: 14px !important;
  margin-left: 5px !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: initial !important;
}
.question-wrapper img {
  vertical-align: middle;
}
.test-execution-log-wrapper {
  overflow-y: auto;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
.beta-version::after {
  content: "beta";
  width: 40px;
  height: 15px;
  line-height: 15px;
  margin-left: 3px;
  background: #EE8E4A;
  text-align: center;
  font-size: 10px;
  font-family: sans-serif;
  text-transform: uppercase;
  color: #fff;
}
.rte-image {
  max-height: 400px;
}

button.video-react-button.video-react-big-play-button.video-react-big-play-button-left {
  top: 50% !important;
  left: 50% !important;
}
.video-react-poster {
  border-radius: 30px !important;
  background-color: white !important;
  -webkit-box-shadow: 2px 2px 10px 2px rgb(255 6 6 / 10%) !important;
}
video.video-react-video {
  border-radius: 30px !important;
}
.video-react {
  background-color: white !important;
}

.player-wrapper {
  position: relative;
  padding-top: 44%;
  margin-left: 14%;
  width: 70%;
  margin-top: 1rem;
  border-radius: 30px !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-device-width: 480px) {
  .player-wrapper {
    position: relative;
    padding-top: 44%;
    margin-left: 0;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 30px !important;
  }
  
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-height: calc(50% - 64px) !important;
}